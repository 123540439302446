import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';

const useStyles = makeStyles((theme) => ({
  tag: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      '& > .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
        fontSize: 20,
      },
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.dark,
    color: '#fff',
  },
  red: {
    backgroundColor: red[600],
    color: '#fff',
  },
  orange: {
    backgroundColor: orange[600],
    color: '#fff',
  },
  ride: {
    backgroundColor: theme.palette.ride.main,
    color: '#fff',
  },
  event: {
    backgroundColor: theme.palette.event.main,
    color: '#fff',
  },
  onlineEvent: {
    backgroundColor: theme.palette.event.main,
    color: '#fff',
  },
  registrationRequired: {
    backgroundColor: red[600],
    color: '#fff',
  },
  approvalRequired: {
    backgroundColor: red[600],
    color: '#fff',
  },
  audienceRestriction: {
    background: 'linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%)',
    //color: '#fff',
  },
}));

const Tag = ({ children, color, className, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Chip
      className={clsx(classes.tag, classes[color], className)}
      size="small"
      {...otherProps}
      label={children}
    />
  );
};

Tag.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  className: PropTypes.string,
};

Tag.defaultProps = {
  color: null,
};

export default Tag;
