import { useEffect } from 'react';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import { makeStyles } from '@material-ui/core/styles';
import Main from './main';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2, 0, 6, 0),
  },
}));

let progressTimer;

const Loading = ({ showProgressTimer, compact }) => {
  const classes = useStyles();

  if (showProgressTimer) {
    useEffect(() => {
      if (!progressTimer) {
        progressTimer = setTimeout(NProgress.start, 300);
      }

      return () => {
        if (progressTimer) {
          clearTimeout(progressTimer);
        }
        NProgress.done();
      };
    });

    if (!progressTimer && typeof window !== 'undefined') {
      return null;
    }
  }

  const content = (
    <div className={classes.wrapper}>
      Loading...
    </div>
  );

  if (compact) {
    return content;
  }

  return (
    <Main>
      {content}
    </Main>
  );
};

Loading.propTypes = {
  showProgressTimer: PropTypes.bool,
  compact: PropTypes.bool,
};

Loading.defaultProps = {
  showProgressTimer: true,
  compact: false,
};

export default Loading;
