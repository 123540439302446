import gql from 'graphql-tag';
import { memberSummaryAttributes, eventEditableAttributes, eventListingAttributes } from './types/event';
import { imageUploadAttributes } from './types/image-uploads';

const commentAttributes = `
  body
  createdAt
  user {
    id
    displayName
    username
    avatar {
      small {
        url
      }
    }
  }
  membership {
    id
    status
    isHost
  }
`;

const inviteAttributes = `
  id
  inviteType
  email
  createdAt
  expiresAt
  memberCount
  eventMember {
    id
    user {
      id
      username
      displayName
    }
  }
  createdByUser {
    id
    username
    displayName
  }
`;

export const eventCommentsQuery = gql`
  query EventCommentsQuery($eventId: ID!) {
    event(id: $eventId) {
      id
      permissions {
        editable
      }
      comments {
        id
        ${commentAttributes}
      }
      commentCount
    }
  }
`;

export const createEventCommentMutation = gql`
  mutation CreateEventCommentMutation($eventId: ID!, $data: EventCommentInput!) {
    createEventComment(eventId: $eventId, data: $data) {
      id
      ${commentAttributes}
    }
  }
`;

const membershipAttributes = `
  status
  notificationPreference
  emailVisibility
  phoneNumberVisibility
`;

export const eventQuery = gql`
  query EventQuery($id: ID!, $inviteToken: String) {
    event(id: $id, inviteToken: $inviteToken) {
      id
      ${eventEditableAttributes}
      cancellationMessage
      cancelledAt
      groupDeniedMessage
      groupDeniedByUser {
        id
        username
        displayName
        avatar {
          small {
            url
          }
        }
      }
      exportToken
      group {
        id
        slug
        name
        public
        image {
          filename
        }
        requestPrompt
        summary
        websiteUrl
        instagramUsername
        permissions {
          uploadImage
          editable
        }
        membersCount
        pastEventsCount
      }
      user {
        id
        username
        displayName
        pronouns
        summary
        avatar {
          med {
            url
          }
        }
        websiteUrl
        instagramUsername
        pastHostedEventsCount
      }
      series {
        id
        name
      }
      membership {
        id
        ${membershipAttributes}
      }
      ${memberSummaryAttributes}
      imageUploads(limit: 12) {
        ${imageUploadAttributes}
      }
      imageUploadsCount
      commentCount
    }
  }
`;

export const eventGalleryQuery = gql`
  query EventGalleryQuery($id: ID!) {
    event(id: $id) {
      id
      imageUploads {
        ${imageUploadAttributes}
      }
      imageUploadsCount
    }
  }
`;

const memberAttributes = `
  id
  status
  isHost
  emailVisible
  phoneNumberVisible
  requestMessage
  user {
    id
    username
    displayName
    email
    phoneNumberDisplay: phoneNumber(format: NATIONAL)
    phoneNumberUri: phoneNumber(format: RFC3966)
    avatar {
      small {
        url
      }
    }
  }
`;

export const eventAttendeesQuery = gql`
  query EventAttendeesQuery($id: ID!) {
    event(id: $id) {
      id
      name
      public
      status
      eventType
      visibility
      registrationType
      audienceRestriction
      series {
        id
      }
      permissions {
        editable
        accessible
        approved
        attendeesViewable
      }
      memberSummary {
        GOING {
          count
        }
        INTERESTED {
          count
        }
        NOT_GOING {
          count
        }
        REQUESTED {
          count
        }
      }
      members {
        ${memberAttributes}
      }
      membership {
        id
        ${membershipAttributes}
      }
      city {
        id
        slug
        name
      }
      group {
        id
        name
      }
    }
  }
`;

export const eventInvitesQuery = gql`
  query EventInvitesQuery($id: ID!) {
    event(id: $id) {
      id
      name
      public
      status
      permissions {
        editable
        accessible
      }
      linkInvites: invites(type: LINK) {
        ${inviteAttributes}
      }
      emailInvites: invites(type: EMAIL) {
        ${inviteAttributes}
      }
    }
  }
`;

export const eventMembershipQuery = gql`
  query EventMembershipQuery($id: ID!) {
    event(id: $id) {
      id
      name
      public
      status
      eventType
      registrationType
      visibility
      permissions {
        editable
        accessible
        attendeesViewable
      }
      ${memberSummaryAttributes}
      membership {
        id
        ${membershipAttributes}
      }
      city {
        id
        slug
        name
      }
      group {
        id
        name
      }
    }
  }
`;

export const updateEventMembershipMutation = gql`
  mutation UpdateEventMembershipMutation($eventId: ID!, $data: EventMemberInput!) {
    updateEventMembership(eventId: $eventId, data: $data) {
      id
      ${membershipAttributes}
    }
  }
`;

export const editEventQuery = gql`
  query EditEventQuery($id: ID!) {
    event(id: $id) {
      id
      ${eventEditableAttributes}
      series {
        id
        name
      }
      ${memberSummaryAttributes}
      membership {
        id
        ${membershipAttributes}
      }
    }
  }
`;

export const copyEventQuery = gql`
  query CopyEventQuery($id: ID!) {
    event(id: $id) {
      id
      ${eventEditableAttributes}
      group {
        id
        slug
        name
      }
    }
  }
`;

export const createEventMutation = gql`
  mutation CreateEventMutation($data: EventInput!, $citySlug: String!) {
    createEvent(data: $data, citySlug: $citySlug) {
      id
      ${eventEditableAttributes}
      series {
        id
        name
      }
      ${memberSummaryAttributes}
      membership {
        id
        ${membershipAttributes}
      }
    }
  }
`;

export const updateEventMutation = gql`
  mutation UpdateEventMutation($id: ID!, $data: EventInput!) {
    updateEvent(id: $id, data: $data) {
      id
      ${eventEditableAttributes}
    }
  }
`;

export const updateEventGroupFeaturedMutation = gql`
  mutation UpdateEventGroupFeaturedMutation($id: ID!, $groupFeatured: Boolean!) {
    updateEventGroupFeatured(id: $id, groupFeatured: $groupFeatured) {
      ${eventListingAttributes}
      group {
        id
        slug
      }
    }
  }
`;

export const updateEventStatusMutation = gql`
  mutation updateEventStatusMutation($eventId: ID!, $status: EventMemberStatus!, $inviteToken: String) {
    updateEventStatus(eventId: $eventId, status: $status, inviteToken: $inviteToken) {
      id
      ${membershipAttributes}
      event {
        id
        ${memberSummaryAttributes}
        permissions {
          editable
          accessible
          joinable
          attendeesViewable
          approved
        }
      }
    }
  }
`;

export const updateEventGroupApprovalMutation = gql`
  mutation UpdateEventGroupApprovalMutation($id: ID!, $data: EventGroupApprovalInput!) {
    updateEventGroupApproval(id: $id, data: $data) {
      id
      status
      group {
        id
        pendingUpcomingEventsCount
      }
    }
  }
`;

export const newMemberFragment = gql`
  fragment NewMemberFragment on Event {
    id
    membership {
      id
      ${membershipAttributes}
    }
  }
`;

export const removeEventCommentMutation = gql`
  mutation DeleteEventCommentRemove($id: ID!) {
    removeEventComment(id: $id) {
      id
    }
  }
`;

export const updateEventMemberNotificationPreferenceMutation = gql`
  mutation UpdateEventMemberNotificationPreferenceMutation(
    $id: ID!,
    $preference: NotificationPreference!
  ) {
    updateEventMemberNotificationPreference(id: $id, preference: $preference) {
      id
      notificationPreference
    }
  }
`;

export const cancelEventMutation = gql`
  mutation CancelEventMutation($id: ID!, $message: String) {
    cancelEvent(id: $id, message: $message) {
      id
      status
      cancellationMessage
      cancelledAt
    }
  }
`;

export const requestJoinEventMutation = gql`
  mutation RequestJoinEventMutation($id: ID!, $message: String, $membership: EventMemberInput) {
    requestJoinEvent(id: $id, message: $message, membership: $membership) {
      id
      ${membershipAttributes}
    }
  }
`;

export const removeEventMutation = gql`
  mutation RemoveEventMutation($id: ID!) {
    removeEvent(id: $id) {
      id
      status
    }
  }
`;

export const approveEventMemberMutation = gql`
  mutation ApproveEventMemberMutation($id: ID!, $message: String) {
    approveEventMember(id: $id, message: $message) {
      ${memberAttributes}
    }
  }
`;

export const denyEventMemberMutation = gql`
  mutation DenyEventMemberMutation($id: ID!, $message: String) {
    denyEventMember(id: $id, message: $message) {
      ${memberAttributes}
    }
  }
`;

export const createEventLinkInviteMutation = gql`
  mutation CreateEventLinkInviteMutation($eventId: ID!) {
    createEventLinkInvite(eventId: $eventId) {
      ${inviteAttributes}
    }
  }
`;

export const createEventEmailInvitesMutation = gql`
  mutation CreateEventEmailInvitesMutation($eventId: ID!, $emails: [String]!) {
    createEventEmailInvites(eventId: $eventId, emails: $emails) {
      ${inviteAttributes}
    }
  }
`;

export const expireEventInviteMutation = gql`
  mutation ExpireEventInviteMutation($id: ID!) {
    expireEventInvite(id: $id) {
      ${inviteAttributes}
    }
  }
`;

export const extendEventInviteMutation = gql`
  mutation ExtendEventInviteMutation($id: ID!) {
    extendEventInvite(id: $id) {
      ${inviteAttributes}
    }
  }
`;

export const resendEventEmailInviteMutation = gql`
  mutation ResendEventEmailInviteMutation($id: ID!) {
    resendEventEmailInvite(id: $id) {
      ${inviteAttributes}
    }
  }
`;

export const eventInviteQuery = gql`
  query EventInviteQuery($id: ID!) {
    eventInvite(id: $id) {
      id
      event {
        id
        name
      }
    }
  }
`;

export const eventHeaderSeriesUpcomingEventsQuery = gql`
  query EventHeaderSeriesUpcomingEventsQuery($id: ID!) {
    eventSeries(id: $id) {
      id
      upcomingEvents(limit: 1) {
        ${eventListingAttributes}
      }
    }
  }
`;
