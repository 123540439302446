import filter from 'lodash/filter';
import { momentParse } from './time';

export const filterNextWeekEvents = (events) => {
  const nextWeekEnd = momentParse().add(1, 'week').endOf('day');
  return filter(events, (event) => {
    return momentParse(event.startAt).isBefore(nextWeekEnd);
  });
};

// IMPORTANT: update server utils when changing
export const eventTypes = {
  RIDE: {
    name: 'Ride'
  },
  EVENT: {
    name: 'Event'
  },
  ONLINE_EVENT: {
    name: 'Online Event',
  },
};

// IMPORTANT: update server utils when changing
export const eventPaces = {
  EASY: {
    name: 'Easy',
    description: 'Under 10 mph',
  },
  LIESURELY: {
    name: 'Leisurely',
    description: '10-12 mph',
  },
  STEADY: {
    name: 'Steady',
    description: '12-14 mph',
  },
  MODERATE: {
    name: 'Moderate',
    description: '14-16 mph',
  },
  BRISK: {
    name: 'Brisk',
    description: '16-18 mph',
  },
  VIGOROUS: {
    name: 'Vigorous',
    description: '18-20 mph',
  },
  STRENUOUS: {
    name: 'Strenuous',
    description: '20-22 mph',
  },
  SUPER_STRENUOUS: {
    name: 'Super Strenuous',
    description: '22+ mph',
  },
};

// IMPORTANT: update server utils when changing
export const eventDistances = {
  '0-3': {
    name: '0-3 miles',
  },
  '3-8': {
    name: '3-8 miles',
  },
  '8-15': {
    name: '8-15 miles',
  },
  '15+': {
    name: '15+ miles',
  },
};

// IMPORTANT: update server utils when changing
export const eventTerrains = {
  MOSTLY_FLAT: {
    name: 'Mostly flat',
    description: 'Trails and/or mostly flat roads with a possible gentle uphill',
  },
  ROLLING: {
    name: 'Rolling',
    description: 'Climbs are short and easy, not too numerous',
  },
  SOME_HILLS: {
    name: 'Some hills',
    description: 'A few short, steep hills, some moderate upgrades and/or longer gentle climbs',
  },
  HILLY: {
    name: 'Hilly',
    description: 'Consistent or continuous climbing',
  },
  OFF_ROAD: {
    name: 'Off-road',
    description: 'Significant unpaved sections',
  },
};

// IMPORTANT: update server utils when changing
export const eventRegroups = {
  NONE: {
    name: 'No regroups',
  },
  OCCASIONAL: {
    name: 'Occasional regroups',
  },
  FREQUENT: {
    name: 'Frequent regroups',
  },
  STAY_TOGETHER: {
    name: 'Stay together',
  },
};

// IMPORTANT: update server utils when changing
export const eventWeatherCancellations = {
  SHOWERS_CANCEL: {
    name: 'Showers cancel',
  },
  STEADY_RAIN_CANCEL: {
    name: 'Steady rain cancels',
  },
  ICE_SNOW_CANCEL: {
    name: 'Ice/snow cancels',
  },
  ALL_WEATHER: {
    name: 'Rain or shine',
  },
};

// IMPORTANT: update server utils when changing
export const prettyVisibility = (event) => {
  const { group, visibility } = event;

  if (visibility === 'PUBLIC') {
    return 'Public';
  } else if (visibility === 'GROUP') {
    return `Members of ${group.name}`;
  } else if (visibility === 'UNLISTED') {
    return 'Unlisted';
  } else if (visibility === 'PRIVATE') {
    return 'Private';
  }
};

// IMPORTANT: update server utils when changing
export const eventAgeRestrictions = {
  GENERAL: {
    name: 'General',
    description: 'most rides and events',
  },
  FAMILY: {
    name: 'Family Friendly',
    description: 'distance, content, and pace is appropriate for kids',
  },
  '21_PLUS': {
    name: 'Adults only (21+)',
    description: 'drinking or other adult activities (nudity, etc)',
  },
};

// IMPORTANT: update server utils when changing
export const eventRegistrationTypes = {
  OPEN: {
    name: 'Open Listing',
  },
  EXTERNAL_REGISTRATION: {
    name: 'External Registration Required',
  },
  APPROVAL_REQUIRED: {
    name: 'Everyday Rides Request to Join',
    description: 'some details hidden until approved by event host: only name, date, description, and contact details are visible without approval',
  },
};
