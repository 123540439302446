import PropTypes from 'prop-types';
import Main from './main';

const ErrorMessage = ({ message, error, compact }) => {
  if (error) {
    console.log(error);
  }

  const content = (
    <p>{message}</p>
  );

  if (compact) {
    return content;
  }

  return (
    <Main>
      {content}
    </Main>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  error: PropTypes.object,
  compact: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  compact: false,
};

export default ErrorMessage;
